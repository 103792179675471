<template>
  <div class="main">
    <my-breadcrumb>我的工作</my-breadcrumb>
    <MainCard ref="MainCard" :dataResource="dataResource" />
  </div>
</template>

<script>
import MyBreadcrumb from '@/components/my-breadcrumb.vue'
import MainCard from '@/components/MainCard.vue'
export default {
  name: 'Work',
  components: {
    MyBreadcrumb,
    MainCard
  },
  data() {
    return {
      dataResource: ['junxiaoList', '4-1']
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/index.less';
</style>
